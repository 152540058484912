import { IAuthProvider } from "../types";
import { BackgroundWorker } from "./BackgroundWorker";
import { Login } from "./Login";
import { LoginCallback } from "./LoginCallback";
import { oktaAuth } from "./backend";

export const oktaProvider: IAuthProvider = {
  meta: {
    id: "okta",
    verboseName: "Okta provider",
    description: "Default method of authentication",
  },
  loginComponent: () => <Login oktaAuth={oktaAuth} />,
  loginCallback: () => <LoginCallback />,
  workerComponent: () => <BackgroundWorker oktaAuth={oktaAuth} />,
};
