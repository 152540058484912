import { IAuthProvider } from "../types";
import { Login } from "./Login";
import { SimpleWorker } from "./SimpleWorker";

export const dummyProvider: IAuthProvider = {
  meta: {
    id: "dummy",
    verboseName: "Dummy - NoAuth",
    description: "Dummy provider only for testing purpose",
  },
  loginComponent: () => <Login />,
  workerComponent: () => <SimpleWorker />,
};
