import { useCallback, useReducer } from "react";
import { useSessionStorage } from "react-use";

export function usePersistReducer<T extends { readonly [k: string]: any }, U>(
  // eslint-disable-next-line no-unused-vars
  reducer: (state: T, action: U) => T,
  key: string,
  initialState: T,
  parse: (
    // eslint-disable-next-line no-unused-vars
    value: any,
  ) =>
    | { readonly success: true; readonly data: T }
    | { readonly success: false; readonly error: any },
) {
  const [savedState, saveState] = useSessionStorage<T>(key, initialState);
  const reducerSessionStorage = useCallback(
    (state, action) => {
      const newState = reducer(state, action);
      const parseResult = parse(newState);
      if (!parseResult.success) {
        console.error(parseResult.error);
        return initialState;
      }
      saveState(parseResult.data);
      return parseResult.data;
    },
    [reducer, saveState, parse, initialState],
  );
  return useReducer(reducerSessionStorage, savedState);
}
