
export interface LogItem {
  // logId: string;
  sortKey: number;
  account: string;
  action: string;
  resource: string;
  requester: string;
  approver: string;
  query: string;
  status: string;
}

export interface RdsResponse {
  items: LogItem[];
  pagination: {
    current_page: number,
    last_key: string,
    next_page: number | null,
    prev_page: number | null,
    total_pages: number,
    total_records: number
  }
}

// export interface InfiniteRdsResponse {
//   pages: RdsResponse[];
//   pageParams: { page: number; lastKey: string | null }[];
// }

export const sevenDaysAgo = new Date();
sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 6);
sevenDaysAgo.setHours(0,0,0,0);

export function convertToStartOfDayUTC(selectedDate) {
  const utcStartOfDay = new Date(
    Date.UTC(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate(),
      selectedDate.getHours(),
      selectedDate.getMinutes(),
      selectedDate.getSeconds()
    )
  );
  
  return utcStartOfDay;
}

export function convertToEndOfDayUTC(selectedDate) {
  const utcEndOfDay = new Date(Date.UTC(
    selectedDate.getFullYear(),
    selectedDate.getMonth(),
    selectedDate.getDate(),
    selectedDate.getHours() + 23,
    selectedDate.getMinutes() + 59,
    selectedDate.getSeconds() + 59
  ));

  return utcEndOfDay;
}

export function formatDateToDDMMYYYY(date: Date): string {
  // Get day, month, and year in UTC
  const day = date.getUTCDate().toString().padStart(2, '0');
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
  const year = date.getUTCFullYear();

  // Return formatted date string
  return `${day}/${month}/${year}`;
}