import { useState } from "react";
import { DatePicker } from "./DatePicker";
interface DateRangePickerProps {
  startDate: Date | undefined;
  endDate: Date | undefined;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void; 
}

export const DateRangePicker = ({ startDate, endDate, setStartDate, setEndDate}: DateRangePickerProps) => {
  const [isStartPickerOpen, setIsStartPickerOpen] = useState<boolean>(false);
  const [isEndPickerOpen, setIsEndPickerOpen] = useState<boolean>(false);

  const handleStartDateSelect = (selectedDate: Date) => {
    setStartDate(selectedDate);
    setIsStartPickerOpen(false);

    // Ensure end date is not before start date
    if (endDate && selectedDate > endDate) {
      setEndDate(selectedDate);
    }
  };

  const handleEndDateSelect = (selectedDate: Date) => {
    setEndDate(selectedDate);
    setIsEndPickerOpen(false);

    // Ensure start date is not after end date
    if (startDate && selectedDate < startDate) {
      console.log(selectedDate)
      setStartDate(selectedDate);
    }
  };

  return (
      <div className="flex items-center gap-4">
        Start Date
        <DatePicker
          defaultDate={startDate}
          onSelect={handleStartDateSelect}
          isOpen={isStartPickerOpen}
          onOpenChange={setIsStartPickerOpen}
        />
        End Date
        <DatePicker
          defaultDate={endDate}
          onSelect={handleEndDateSelect}
          isOpen={isEndPickerOpen}
          onOpenChange={setIsEndPickerOpen}
        />
      </div>
  );
};
