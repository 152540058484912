const LOCAL_STORAGE_KEY = "_authRedirectTo";

export const redirector = {
  store: (target: string) => localStorage.setItem(LOCAL_STORAGE_KEY, target),
  // eslint-disable-next-line no-unused-vars
  restore: (fallback: string, navigate: (target) => void) => {
    const target = localStorage.getItem(LOCAL_STORAGE_KEY) ?? fallback;
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    if (target) {
      navigate(target);
    }
  },
};
