const KEY = "token";

var TOKEN: string | null = null;

export function storeToken(token: string | null) {
  TOKEN = token;
  if (token === null) {
    sessionStorage.removeItem(KEY);
    return;
  }
  sessionStorage.setItem(KEY, token);
}

export function getToken() {
  if (TOKEN) {
    return TOKEN;
  }
  TOKEN = sessionStorage.getItem(KEY);
  return TOKEN;
}
