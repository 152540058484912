import { Button } from "@nexus/lib-react/dist/core/Button";
import { Info } from "lucide-react";
import React, { useCallback } from "react";

import { useAuth } from "../../AuthProvider";
import { LoginPayload } from "../../types";

const dummySuccessPromise = () =>
  new Promise<LoginPayload>((resolve) => {
    setTimeout(() => {
      resolve({
        token: "DUMMY-ACCESS-TOKEN",
        canLogout: true,
      });
    }, 300);
  });

const dummyFailPromise = () =>
  new Promise<LoginPayload>((_, reject) => {
    setTimeout(() => {
      reject(
        new Error("Oh no... Something has gone wrong but it is intentional."),
      );
    }, 100);
  });

export function Login(): React.ReactElement {
  const { login } = useAuth();
  const onLoginClick = useCallback(() => login(dummySuccessPromise), [login]);
  const onErrorClick = useCallback(() => login(dummyFailPromise), [login]);

  return (
    <div className="flex w-full items-center justify-center">
      <div className="flex flex-col gap-2 bg-neutral-50 p-5">
        <h1 className="mb-3 text-xl">Dummy provider</h1>
        <div className="flex gap-2 border border-yellow-400 bg-yellow-200 p-3">
          <Info className="text-yellow-800" />{" "}
          <span>
            Use for local development <strong>only</strong>.
          </span>
        </div>
        <Button onClick={onLoginClick}>Login as an administrator</Button>
        <Button variant="alternative" onClick={onErrorClick}>
          Login with an error
        </Button>
      </div>
    </div>
  );
}
