import { RandomLoader } from "@nexus/lib-react/dist/core/Loading/RandomLoader";
import React from "react";
import { useParams } from "react-router-dom";

import { useAuth } from "./AuthProvider";
import { getProviderOrThrow } from "./providers";
import { AuthProviderEnumSchema } from "./types";

export function LoginCallbackDispatcher(): React.ReactNode {
  const { isLoading } = useAuth();
  const { provider: rawProvider } = useParams();
  const provider = AuthProviderEnumSchema.parse(rawProvider);
  const LoginCallbackComponent = getProviderOrThrow(provider).loginCallback;

  if (isLoading) {
    return <RandomLoader />;
  }
  return LoginCallbackComponent && <LoginCallbackComponent />;
}
