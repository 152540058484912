import { RandomLoader } from "@nexus/lib-react/dist/core/Loading/RandomLoader";
import OktaAuth from "@okta/okta-auth-js";
import { useCallback } from "react";
import { useTimeoutFn } from "react-use";

type LoginProps = {
  readonly oktaAuth: OktaAuth;
};

export function Login({ oktaAuth }: LoginProps) {
  const loginOkta = useCallback(
    () => oktaAuth.signInWithRedirect(),
    [oktaAuth],
  );

  useTimeoutFn(loginOkta, 1000);

  return (
    <div className="h-[100vh] w-[100vw] bg-[#F5F5F5]">
      <div className="m-auto flex max-w-[60vh] flex-col self-center pt-[17vh] text-center">
        <div className="inline text-[6vh]">
          <div className="text-blu mr-[2vh] inline text-[6vh] font-extrabold">
            Cora
          </div>
        </div>
        <div className="order flex w-full items-center justify-center p-8">
          <RandomLoader center={false} />
        </div>
        <a
          className="m-auto mt-4 inline-block w-48 cursor-pointer font-bold hover:text-green-600"
          onClick={loginOkta}
        >
          Go to OKTA now
        </a>
      </div>
    </div>
  );
}
