/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export interface GitlabGroupResponse {
  full_name?: string;
  group_id?: number;
  group_name?: string;
}

export interface ApiModelAPIError {
  message?: string;
  status_code?: number;
}

export interface ApiModelIntegrationTemplatesResponse {
  integration_name?: string;
  integration_type?: string;
  project_id?: number;
  ref?: string;
}

export interface ApiModelLifecycleResponse {
  description?: string;
  display_name?: string;
  lifecycle?: string;
}

export type ApiModelObjectType = "service";

export interface ApiModelPermissionCheckRequest {
  actions?: IamAction[];
  object_type?: ApiModelObjectType;
  object_uid?: string;
}

export interface ApiModelPermissionCheckResponse {
  decisions?: Record<string, boolean>;
  object_type?: ApiModelObjectType;
  object_uid?: string;
}

export interface ApiModelServiceCreateRequest {
  description?: string;
  gitlab_group?: number;
  lifecycle?: string;
  parameters?: ModelParameter[];
  project_name?: string;
  slug?: string;
  tags?: string[];
  team_uid?: string;
  template_id?: string;
  tier?: string;
  workload_target_uid?: string;
}

export interface ApiModelServiceCreateResponse {
  http_pointer?: string;
  trace_id?: string;
  uid?: string;
}

export interface ApiModelServiceResponse {
  argo_projects?: ModelArgoProjectPair;
  created?: number;
  description?: string;
  gitlab_group?: number;
  gitlab_project?: ModelGitlabProject;
  last_decommissioning_status?: ModelWorkflowRun;
  last_provisioning_status?: ModelWorkflowRun;
  lifecycle?: string;
  parameters?: ModelParameter[];
  project_name?: string;
  slug?: string;
  status?: string;
  tags?: string[];
  team?: ModelTeam;
  template?: ModelTemplate;
  tier?: string;
  uid?: string;
  workflow_history?: ModelWorkflowRun[];
  workload_target?: ModelWorkloadTarget;
}

export interface ApiModelTeamMetaDataResponse {
  business_unit?: string;
  customer_unit?: string;
  pillar_or_tribe?: string;
  stream?: string;
  team_name?: string;
  team_okta_group?: string;
  uid?: string;
}

export interface ApiModelTemplateParametersResponse {
  default_value?: string;
  description?: string;
  is_optional?: boolean;
  name?: string;
  options?: ("string" | "choice" | "bool")[];
  type?: string;
}

export interface ApiModelTemplateResponse {
  id?: string;
  integration_templates?: ApiModelIntegrationTemplatesResponse[];
  name?: string;
  parameters?: ApiModelTemplateParametersResponse[];
  project_id?: number;
  ref?: string;
  tags?: string[];
  uid?: string;
}

export interface ApiModelTierResponse {
  description?: string;
  display_name?: string;
  tier?: string;
}

export interface ApiModelValidationResponse {
  reason?: string;
  valid?: boolean;
}

export interface ApiModelWorkloadTargetResponse {
  name?: string;
  uid?: string;
}

export type CommonEnvType = 0 | 1;

export type IamAction =
  | "GitlabGroup:Create"
  | "GitlabGroup:List"
  | "GitlabGroup:Sync"
  | "Permission:Check"
  | "Service:Create"
  | "Service:Decommission"
  | "Service:Get"
  | "Service:List"
  | "ServiceLifecycle:List"
  | "ServiceName:Validate"
  | "ServiceSlug:Create"
  | "ServiceSlug:Validate"
  | "ServiceStatus:List"
  | "ServiceTier:List"
  | "Team:Get"
  | "Team:List"
  | "Template:Create"
  | "Template:Delete"
  | "Template:Get"
  | "Template:List"
  | "WorkloadTarget:Create"
  | "WorkloadTarget:Get";

export interface ModelArgoProject {
  argo_server_url?: string;
  environment?: CommonEnvType;
  k8s_namespace?: string;
  name?: string;
  token?: string;
}

export interface ModelArgoProjectPair {
  production?: ModelArgoProject;
  staging?: ModelArgoProject;
}

export interface ModelEnvironment {
  availability_zones?: string[];
  aws_account_number?: string;
  aws_region?: string;
  eks_private_subnet_ids?: string[];
  eks_public_subnet_ids?: string[];
  environment_code?: string;
  environment_name?: string;
  ingress_acm_certificate?: string;
  ingress_dns_zone?: string;
  kubernetes_cluster_arn?: string;
  kubernetes_cluster_name?: string;
  kubernetes_cluster_oidc?: string;
  pipeline_iam_role?: string;
  private_subnet_ids?: string[];
  public_subnet_ids?: string[];
  terraform_lock_table?: string;
  terraform_state_bucket?: string;
  vpc_id?: string;
}

export interface ModelGitlabGroup {
  full_name?: string;
  full_path?: string;
  id?: number;
  name?: string;
  parent_id?: number;
  path?: string;
  uid?: string;
  web_url?: string;
}

export interface ModelGitlabProject {
  container_registry?: string;
  default_branch?: string;
  full_path?: string;
  id?: number;
  name?: string;
  path?: string;
  path_with_namespace?: string;
  web_url?: string;
}

export interface ModelIntegrationTemplates {
  integration_name?: string;
  integration_type?: string;
  project_id?: number;
  ref?: string;
}

export interface ModelParameter {
  name?: string;
  value?: any;
}

export interface ModelSlackChannel {
  id?: string;
  name?: string;
}

export interface ModelStepError {
  message?: string;
}

export interface ModelStepRun {
  created_at?: number;
  description?: string;
  error?: ModelStepError;
  name?: string;
  status?: ModelStepStatus;
  updated_at?: number;
}

export type ModelStepStatus = "pending" | "canceled" | "error" | "running" | "success" | "waiting";

export interface ModelTeam {
  aura_team_name?: string;
  business_unit?: string;
  business_unit_id?: string;
  customer_unit?: string;
  customer_unit_id?: string;
  email?: string;
  opslevel_belongs_to?: string;
  opslevel_dependency_of?: string;
  pillar_or_tribe?: string;
  pillar_or_tribe_id?: string;
  slack_alerts_channels?: ModelSlackChannel[];
  slack_contact_channels?: ModelSlackChannel[];
  slack_handle?: string;
  slack_incidents_channels?: ModelSlackChannel[];
  stream?: string;
  stream_id?: string;
  team_id?: string;
  team_name?: string;
  team_okta_group?: string;
  uid?: string;
}

export interface ModelTemplate {
  id?: string;
  integration_templates?: ModelIntegrationTemplates[];
  name?: string;
  parameters?: ModelTemplateParameters[];
  project_id?: number;
  ref?: string;
  tags?: string[];
}

export interface ModelTemplateParameters {
  default_value?: string;
  description?: string;
  is_optional?: boolean;
  name?: string;
  options?: ("string" | "choice" | "bool")[];
  type?: string;
}

export interface ModelWorkflowRun {
  created_at?: number;
  detailed_status?: ModelStepRun[];
  status?: ModelWorkflowStatus;
  trace_id?: string;
  type?: ModelWorkflowType;
  uid?: string;
  updated_at?: number;
}

export type ModelWorkflowStatus = "pending" | "failed" | "running" | "success";

export type ModelWorkflowType = "provisioning" | "decommissioning";

export interface ModelWorkloadTarget {
  atlantis_webhook_token?: string;
  atlantis_webhook_url?: string;
  monitoring_account_id?: string;
  monitoring_apm_license?: string;
  monitoring_cluster_prefix?: string;
  name?: string;
  production?: ModelEnvironment;
  sentry_organization?: string;
  sentry_team?: string;
  sentry_token?: string;
  sonarqube_admin_token?: string;
  sonarqube_nvd_token?: string;
  sonarqube_token?: string;
  sonarqube_url?: string;
  staging?: ModelEnvironment;
  uid?: string;
}

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "/api/" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Service Shaper API
 * @version 1.0
 * @baseUrl /api/
 * @contact
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  cache = {
    /**
     * @description Returns list of gitlab groups available to use for new services.
     *
     * @tags Gitlab
     * @name GitlabGroupList
     * @summary List Gitlab Groups
     * @request GET:/cache/gitlab-group/
     * @secure
     */
    gitlabGroupList: (
      query?: {
        /** Number of groups per page to return. Default and maximum - 1000. */
        itemsPerPage?: string;
        /** The starting key for next page of groups. */
        startKey?: string;
        /** Filter groups. Filter must be in <field>:<operation>:<value> format. Operations: eq - equals, sw - starts with. */
        filter?: string;
        /** Sort by field. Default - name */
        sortBy?: string;
        /** Sort order - asc/desc. Default - asc. */
        sortOrder?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GitlabGroupResponse[], ApiModelAPIError>({
        path: `/cache/gitlab-group/`,
        method: "GET",
        query: query,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Create a new record for the GitLab group
     *
     * @tags Gitlab
     * @name GitlabGroupCreate
     * @summary Create a GitLab group
     * @request POST:/cache/gitlab-group/
     * @secure
     */
    gitlabGroupCreate: (group: ModelGitlabGroup, params: RequestParams = {}) =>
      this.request<void, ApiModelAPIError>({
        path: `/cache/gitlab-group/`,
        method: "POST",
        body: group,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get a record for the particular GitLab group
     *
     * @tags Gitlab
     * @name GitlabGroupIdList
     * @summary Get single GitLab group
     * @request GET:/cache/gitlab-group/:id
     * @secure
     */
    gitlabGroupIdList: (id: number, params: RequestParams = {}) =>
      this.request<GitlabGroupResponse, ApiModelAPIError>({
        path: `/cache/gitlab-group/${id}`,
        method: "GET",
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns list of lifecycles from OpsLevel to classify a service.
     *
     * @tags ServiceCatalog
     * @name ServiceLifecycleList
     * @summary Get list of lifecycles from OpsLevel
     * @request GET:/cache/service-lifecycle/
     * @secure
     */
    serviceLifecycleList: (params: RequestParams = {}) =>
      this.request<ApiModelLifecycleResponse[], ApiModelAPIError>({
        path: `/cache/service-lifecycle/`,
        method: "GET",
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Lists all possible service statuses.
     *
     * @tags Service Filtering
     * @name ServiceStatusesList
     * @summary List all possible service statuses
     * @request GET:/cache/service-statuses/
     * @secure
     */
    serviceStatusesList: (params: RequestParams = {}) =>
      this.request<any, ApiModelAPIError>({
        path: `/cache/service-statuses/`,
        method: "GET",
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Returns list of tiers from OpsLevel to classify a service.
     *
     * @tags ServiceCatalog
     * @name ServiceTierList
     * @summary Get list of tiers from OpsLevel
     * @request GET:/cache/service-tier/
     * @secure
     */
    serviceTierList: (params: RequestParams = {}) =>
      this.request<ApiModelTierResponse[], ApiModelAPIError>({
        path: `/cache/service-tier/`,
        method: "GET",
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns list of teams with details.
     *
     * @tags Team
     * @name TeamList
     * @summary List teams
     * @request GET:/cache/team/
     * @secure
     */
    teamList: (
      query?: {
        /** Number of teams per page to return. Default and maximum - 1000. */
        itemsPerPage?: string;
        /** The starting key for next page of teams. */
        startKey?: string;
        /** Filter teams. Filter must be in <field>:<operation>:<value> format. Operations: eq - equals, sw - starts with. */
        filter?: string;
        /** Sort by field. Default - team_name */
        sortBy?: string;
        /** Sort order - asc/desc. Default - asc. */
        sortOrder?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ApiModelTeamMetaDataResponse[], ApiModelAPIError>({
        path: `/cache/team/`,
        method: "GET",
        query: query,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Find single team details by UID.
     *
     * @tags Team
     * @name TeamDetail
     * @summary Find single team details by UID.
     * @request GET:/cache/team/{teamUid}
     * @secure
     */
    teamDetail: (teamUid: string, params: RequestParams = {}) =>
      this.request<ApiModelTeamMetaDataResponse, ApiModelAPIError>({
        path: `/cache/team/${teamUid}`,
        method: "GET",
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  permissions = {
    /**
     * @description Check if the user has specified permissions for an object.
     *
     * @tags Permissions
     * @name CheckForObject
     * @summary Check permissions for an object
     * @request POST:/permissions/check
     * @secure
     */
    checkForObject: (template: ApiModelPermissionCheckRequest, params: RequestParams = {}) =>
      this.request<ApiModelPermissionCheckResponse, ApiModelAPIError>({
        path: `/permissions/check`,
        method: "POST",
        body: template,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  service = {
    /**
     * @description Returns the list of services. Valid fields for filtering and sorting: project_name, team, status, created.
     *
     * @tags Service
     * @name ServiceList
     * @summary Returns information about all services.
     * @request GET:/service/
     * @secure
     */
    serviceList: (
      query?: {
        /** Number of services per page to return. Default and maximum - 1000. */
        itemsPerPage?: string;
        /** The starting key for next page of services. */
        startKey?: string;
        /** Filter services. Filter must be in <field>:<operation>:<value> format. Operations: eq - equals, sw - starts with. */
        filter?: string;
        /** Sort by field. Default - created */
        sortBy?: string;
        /** Sort order - asc/desc. Default - desc. */
        sortOrder?: string;
        /** Filter services by tags. Must be a coma-separated string. Values are case-sensitive. */
        tags?: string;
        /** Perform a deep search by all service parameters. The value is case-insensitive. */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ApiModelServiceResponse[], ApiModelAPIError>({
        path: `/service/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Create and provision a new service from an existing template.
     *
     * @tags Service
     * @name ServiceCreate
     * @summary Create new service
     * @request POST:/service/
     * @secure
     */
    serviceCreate: (service: ApiModelServiceCreateRequest, params: RequestParams = {}) =>
      this.request<ApiModelServiceCreateResponse, ApiModelAPIError>({
        path: `/service/`,
        method: "POST",
        body: service,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Creates task for archiving service repository and cleans up all of its infrastructure and integrations.
     *
     * @tags Service
     * @name DecommissionCreate
     * @summary Decommission a service
     * @request POST:/service/{serviceUID}/decommission
     * @secure
     */
    decommissionCreate: (serviceUid: string, params: RequestParams = {}) =>
      this.request<void, ApiModelAPIError>({
        path: `/service/${serviceUid}/decommission`,
        method: "POST",
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Returns information about the current status of a service and a URL to the generated batch file.
     *
     * @tags Service
     * @name ServiceDetail
     * @summary Get single service details
     * @request GET:/service/{serviceUid}
     * @secure
     */
    serviceDetail: (serviceUid: string, params: RequestParams = {}) =>
      this.request<ApiModelServiceResponse, ApiModelAPIError>({
        path: `/service/${serviceUid}`,
        method: "GET",
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  syncGitlabGroups = {
    /**
     * @description Synchronize GitLab groups with the Gitlab instance. This is a management endpoint accessible only in-cluster.
     *
     * @tags Gitlab, Management-Endpoints
     * @name SyncGitlabGroupsCreate
     * @summary Create a GitLab group
     * @request POST:/sync-gitlab-groups
     * @secure
     */
    syncGitlabGroupsCreate: (params: RequestParams = {}) =>
      this.request<void, ApiModelAPIError>({
        path: `/sync-gitlab-groups`,
        method: "POST",
        secure: true,
        ...params,
      }),
  };
  template = {
    /**
     * @description Returns list of templates available to use for new services.
     *
     * @tags Template
     * @name TemplateList
     * @summary List templates
     * @request GET:/template/
     * @secure
     */
    templateList: (
      query?: {
        /** Number of templates per page to return. Default and maximum - 1000. */
        itemsPerPage?: string;
        /** The starting key for next page of templates. */
        startKey?: string;
        /** Filter templates. Filter must be in <field>:<operation>:<value> format. Operations: eq - equals, sw - starts with. */
        filter?: string;
        /** Sort by field. Default - name */
        sortBy?: string;
        /** Sort order - asc/desc. Default - asc. */
        sortOrder?: string;
        /** Filter templates by tags. Must be a coma-separated string. Values are case-sensitive. */
        tags?: string;
        /** Perform a deep search by all template parameters. The value is case-insensitive. */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ApiModelTemplateResponse[], ApiModelAPIError>({
        path: `/template/`,
        method: "GET",
        query: query,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Create a new record for the template
     *
     * @tags Template
     * @name TemplateCreate
     * @summary Create a template
     * @request POST:/template/
     * @secure
     */
    templateCreate: (template: ModelTemplate, params: RequestParams = {}) =>
      this.request<void, ApiModelAPIError>({
        path: `/template/`,
        method: "POST",
        body: template,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Returns a single template by its UID.
     *
     * @tags Template
     * @name TemplateDetail
     * @summary Get Template
     * @request GET:/template/{templateId}
     * @secure
     */
    templateDetail: (templateId: string, params: RequestParams = {}) =>
      this.request<ApiModelTemplateResponse, ApiModelAPIError>({
        path: `/template/${templateId}`,
        method: "GET",
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Deletes a single template.
     *
     * @tags Template
     * @name TemplateDelete
     * @summary Delete Template
     * @request DELETE:/template/{templateId}
     * @secure
     */
    templateDelete: (templateId: string, params: RequestParams = {}) =>
      this.request<void, ApiModelAPIError>({
        path: `/template/${templateId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  validation = {
    /**
     * @description Validate if a name of service is available and compliant with naming conventions
     *
     * @tags Validation
     * @name ServiceNameList
     * @summary Validate service name
     * @request GET:/validation/service-name/
     * @secure
     */
    serviceNameList: (
      query: {
        /** Name of the service. */
        serviceName: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ApiModelValidationResponse, ApiModelAPIError>({
        path: `/validation/service-name/`,
        method: "GET",
        query: query,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Validate if a slug of service is available and compliant with naming conventions
     *
     * @tags Validation
     * @name ServiceSlugList
     * @summary Validate service slug
     * @request GET:/validation/service-slug/
     * @secure
     */
    serviceSlugList: (
      query: {
        /** Name of the service. */
        serviceSlug: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ApiModelValidationResponse, ApiModelAPIError>({
        path: `/validation/service-slug/`,
        method: "GET",
        query: query,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Generate a service slug from a given name.
     *
     * @tags Validation
     * @name ServiceSlugCreate
     * @summary Generate service slug
     * @request POST:/validation/service-slug/
     * @secure
     */
    serviceSlugCreate: (serviceName: string, params: RequestParams = {}) =>
      this.request<string, ApiModelAPIError>({
        path: `/validation/service-slug/`,
        method: "POST",
        body: serviceName,
        secure: true,
        type: ContentType.Text,
        ...params,
      }),
  };
  workloadTarget = {
    /**
     * @description Returns list of workload targets available to use for new services.
     *
     * @tags Deployment
     * @name WorkloadTargetList
     * @summary List Workload Targets
     * @request GET:/workload-target/
     * @secure
     */
    workloadTargetList: (
      query?: {
        /** Number of workload targets per page to return. Default and maximum - 1000. */
        itemsPerPage?: string;
        /** The starting key for next page of workload targets. */
        startKey?: string;
        /** Filter workload targets. Filter must be in <field>:<operation>:<value> format. Operations: eq - equals, sw - starts with. */
        filter?: string;
        /** Sort by field. Default - name */
        sortBy?: string;
        /** Sort order - asc/desc. Default - asc. */
        sortOrder?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ApiModelWorkloadTargetResponse[], ApiModelAPIError>({
        path: `/workload-target/`,
        method: "GET",
        query: query,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Create a new workload Target.
     *
     * @tags Deployment
     * @name WorkloadTargetCreate
     * @summary Create a Workload Target
     * @request POST:/workload-target/
     * @secure
     */
    workloadTargetCreate: (target: ModelWorkloadTarget, params: RequestParams = {}) =>
      this.request<void, ApiModelAPIError>({
        path: `/workload-target/`,
        method: "POST",
        body: target,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
}
