import { getToken } from "@/auth/storeToken";
import { convertToEndOfDayUTC, convertToStartOfDayUTC, formatDateToDDMMYYYY } from "@/types/types";
import { Button } from "@/ui/button";
import { useQuery } from "@tanstack/react-query";
import { FileSpreadsheet } from "lucide-react";

const fetchCsv = async (startDate: Date, endDate: Date): Promise<Blob> => {

  const queryParams = new URLSearchParams({
    limit: '15', // Adjust as needed
    from: parseInt((convertToStartOfDayUTC(startDate).getTime() / 1000).toFixed(0)).toString(),
    to: parseInt((convertToEndOfDayUTC(endDate).getTime() / 1000).toFixed(0)).toString(),
  });

  const headers: Record<string, string> = {};

  var token = getToken()
  if (!token) {
    token = ""
  }

  headers['Authorization'] = "Bearer "+token;

  const response = await fetch(`https://cora-web-api.cora.prd.eu.olx.org/logs/rds-queries/download-spreadsheet?${queryParams.toString()}`, {
    method: 'GET',
    headers: headers,
  });
  if (!response.ok) {
    throw new Error('Failed to fetch CSV');
  }
  return response.blob(); // Convert response to a Blob (binary data)
};

interface SpreadsheetButtonProps {
  startDate: Date;
  endDate: Date;
}

export const SpreadsheetButton = ({ startDate, endDate }: SpreadsheetButtonProps) => {
  const { isFetching, refetch } = useQuery<Blob>({
    queryKey: ['downloadCsv'],
    queryFn: () => fetchCsv(startDate, endDate),
    enabled: false, // Disable automatic fetching on component mount
  });

  const downloadCsv = () => {
    refetch().then((res) => {
      if (res.data) {
        const url = window.URL.createObjectURL(res.data); // Create an object URL from the Blob
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `logs-${formatDateToDDMMYYYY(startDate)}-${formatDateToDDMMYYYY(endDate)}.xlsx`); // Set the file name for download
        document.body.appendChild(link);
        link.click(); // Programmatically click the link to trigger download
        link.remove(); // Clean up the link element
      }
    });
  };

  return (
    <Button onClick={downloadCsv} disabled={isFetching || !startDate || !endDate} className="w-[164px] flex items-center space-x-2 drop-shadow-olx m-1 rounded-md border text-coal transition-all duration-150 hover:ring-gray-700 hover:ring hover:ring-inset">
      <FileSpreadsheet className="w-4 h-4" />
      <span>Download</span>
    </Button>
  );
}