import React from "react";

import { Button } from "@/ui/button"

export function DefaultErrorBoundary(): React.ReactElement {
  return (
    <div className="flex w-full justify-center content-center h-svh items-center flex-col gap-4">
      <span>Something went wrong.</span>
      <Button onClick={() => window.location.reload()}>Try again</Button>
    </div>
  );
}