import React, { useCallback, useMemo } from "react";
import toast from "react-hot-toast";
import { Outlet, useNavigate } from "react-router-dom";

import { Api } from "@/api/__generated__/api";

import { authReducer } from "./authReducer";
import { getProviderOrThrow } from "./providers";
import { redirector } from "./redirector";
import { storeToken } from "./storeToken";
import {
  Actions,
  AuthProviderEnumSchema,
  AuthStateContextType,
  LoginPayload,
  authStateSchema,
} from "./types";
import { usePersistReducer } from "./usePersistReducer";

const AUTH_STORAGE_KEY = "__auth__";

const AuthStateContext = React.createContext<AuthStateContextType | undefined>(
  undefined,
);

function useWorkerComponent(provider: AuthProviderEnumSchema | null) {
  return useMemo(() => {
    if (provider === null) return null;
    try {
      return getProviderOrThrow(provider).workerComponent ?? null;
    } catch {
      console.error(`There is no worker component for ${provider}`);
      return null;
    }
  }, [provider]);
}

const useLogin = (dispatch: React.Dispatch<Actions>) => {
  const navigate = useNavigate();
  return useCallback(
    (loginCallback: () => Promise<LoginPayload>) => {
      dispatch({ type: "LOGIN" });
      loginCallback()
        .then((payload) => {
          dispatch({
            type: "LOGIN_SUCCESS",
            payload,
          });
          storeToken(payload.token);
          toast.success("You are logged in!");
          redirector.restore("/home", navigate);
        })
        .catch((reason) => {
          dispatch({ type: "LOGIN_FAILED", payload: { error: reason } });
          toast.error(reason);
        });
    },
    [dispatch, navigate],
  );
};

const useLogout = (dispatch: React.Dispatch<Actions>) => {
  const navigate = useNavigate();
  return useCallback(
    (logoutCallback?: () => Promise<LoginPayload>) => {
      dispatch({ type: "LOGOUT" });
      if (logoutCallback) {
        logoutCallback()
          .then(() => {
            dispatch({ type: "LOGOUT_SUCCESS" });
            toast.success("You are logged out!");
            navigate("/home");
          })
          .catch((reason) => {
            dispatch({ type: "LOGOUT_FAILED", payload: { error: reason } });
            toast.error(reason);
          });
      } else {
        dispatch({ type: "LOGOUT_SUCCESS" });
      }
    },
    [dispatch, navigate],
  );
};

type AuthProviderProps = {
  readonly apiClient: Api<unknown>;
};

export function AuthProvider({ apiClient }: AuthProviderProps) {
  const [state, dispatch] = usePersistReducer(
    authReducer,
    AUTH_STORAGE_KEY,
    {
      provider: null,
      isAuthenticated: false,
      isLoading: false,
      canLogout: false,
    },
    (value) => authStateSchema.safeParse(value),
  );

  const WorkerComponent = useWorkerComponent(state.provider);
  const login = useLogin(dispatch);
  const logout = useLogout(dispatch);
  const changeProvider = useCallback(
    (provider: AuthProviderEnumSchema) => {
      dispatch({ type: "CHANGE_PROVIDER", payload: { provider } });
    },
    [dispatch],
  );

  const value = useMemo(
    () => ({
      ...state,
      login,
      logout,
      changeProvider,
      apiClient,
    }),
    [changeProvider, login, logout, state, apiClient],
  );

  return (
    <AuthStateContext.Provider value={value}>
      {WorkerComponent && <WorkerComponent />}
      <Outlet />
    </AuthStateContext.Provider>
  );
}

export function useAuth(): AuthStateContextType {
  const context = React.useContext(AuthStateContext);
  if (!context) throw new Error("useAuth must be inside AuthStateContext");
  return context;
}
