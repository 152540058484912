import React from "react";
import { z } from "zod";

import { Api } from "@/api/__generated__/api";

export const AuthProviderEnumSchema = z.union([
  z.literal("dummy"),
  z.literal("okta"),
]);
export type AuthProviderEnumSchema = z.infer<typeof AuthProviderEnumSchema>;

export const authStateSchema = z.object({
  provider: AuthProviderEnumSchema.nullable(),
  isAuthenticated: z.boolean(),
  isLoading: z.boolean(),
  canLogout: z.boolean(),
  error: z.string().optional(),
});

export type AuthState = z.infer<typeof authStateSchema>;

type InitState = { readonly type: "INIT_STATE"; readonly payload: AuthState };
type ChangeProvider = {
  readonly type: "CHANGE_PROVIDER";
  readonly payload: { readonly provider: AuthProviderEnumSchema };
};
type Login = { readonly type: "LOGIN" };
type LoginSuccess = {
  readonly type: "LOGIN_SUCCESS";
  readonly payload: LoginPayload;
};
export type LoginFailed = {
  readonly type: "LOGIN_FAILED";
  readonly payload: { readonly error: string };
};
type Logout = { readonly type: "LOGOUT" };
type LogoutSuccess = { readonly type: "LOGOUT_SUCCESS" };
export type LogoutFailed = {
  readonly type: "LOGOUT_FAILED";
  readonly payload: { readonly error: string };
};

export type Actions =
  | InitState
  | ChangeProvider
  | Login
  | LoginSuccess
  | LoginFailed
  | Logout
  | LogoutSuccess
  | LogoutFailed;

export type LoginPayload = {
  readonly token: string;
  readonly canLogout: boolean;
};

export type AuthStateContextType = {
  readonly isAuthenticated: boolean;
  readonly isLoading: boolean;
  readonly canLogout: boolean;
  readonly apiClient: Api<unknown>;
  readonly changeProvider: (
    // eslint-disable-next-line no-unused-vars
    provider: AuthProviderEnumSchema,
    // eslint-disable-next-line no-unused-vars
    component: React.FC,
  ) => void;
  readonly login: (
    // eslint-disable-next-line no-unused-vars
    action: () => Promise<LoginPayload>,
  ) => void;
  readonly logout: () => void;
};
