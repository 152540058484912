import { Outlet, useNavigate } from "react-router-dom";

import { useAuth } from "../auth";

export function RequiredAuth() {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  if (!isAuthenticated) {
    navigate("/");
  }

  return <Outlet />;
}
