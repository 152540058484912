import { Actions, AuthState, LoginFailed, LogoutFailed } from "./types";

function failedState(
  state: AuthState,
  action: LoginFailed | LogoutFailed,
): AuthState {
  return {
    ...state,
    isLoading: false,
    error: action.payload.error,
  };
}

export function authReducer(state: AuthState, action: Actions): AuthState {
  const loadingState: AuthState = {
    ...state,
    isLoading: true,
    error: undefined,
  };
  switch (action.type) {
    case "INIT_STATE":
      return action.payload;
    case "CHANGE_PROVIDER":
      return {
        ...state,
        isAuthenticated: false,
        provider: action.payload.provider,
      };
    case "LOGIN":
      return loadingState;
    case "LOGIN_SUCCESS":
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        canLogout: action.payload.canLogout,
      };
    case "LOGIN_FAILED":
      return failedState(state, action);
    case "LOGOUT":
      return loadingState;
    case "LOGOUT_SUCCESS":
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        canLogout: false,
        provider: null,
      };
    case "LOGOUT_FAILED":
      return failedState(state, action);
    default:
      console.warn("Default behaviour!");
      return state;
  }
}
