import { NavLink } from "react-router-dom";

import CoraLogo from "../assets/cora_no_bg.png"

export const TopBar = () => {
  const Decorations = () => {
    return (
      <div className="flex items-center gap-4 text-xl font-medium tracking-wide">
        <img className="h-12" src={CoraLogo} alt="Cora Logo" />
        <span>Cora</span>
      </div>
    );
  };

  return (
    <>
      <div className="drop-shadow-md flex min-h-[72px] h-[72px] z-[40] w-full items-center gap-2 bg-greenish-100 text-black sm:px-16">
        <NavLink to="/home">
          <Decorations />
        </NavLink>
      </div>
    </>
  );
};
