import { OktaAuth } from "@okta/okta-auth-js";
import { useCallback, useEffect } from "react";

import { useAuth } from "../../AuthProvider";

type BackgroundWorkerProps = {
  readonly oktaAuth: OktaAuth;
};

export function BackgroundWorker({ oktaAuth }: BackgroundWorkerProps) {
  const { login } = useAuth();

  const handler = useCallback(
    (opts) => {
      login(async () => {
        return {
          token: opts.idToken.idToken,
          canLogout: false,
        };
      });
    },
    [login],
  );

  useEffect(() => {
    if (!oktaAuth) {
      return;
    }
    oktaAuth.authStateManager.subscribe(handler);
    return () => {
      oktaAuth.authStateManager.unsubscribe(handler);
    };
  }, [oktaAuth, handler]);

  useEffect(() => {
    if (!oktaAuth) {
      return;
    }
    const handle = async () => {
      if (oktaAuth.isLoginRedirect()) {
        await oktaAuth.handleRedirect();
      }
    };
    handle();
  }, [oktaAuth]);

  return <></>;
}
