import * as z from "zod";

import { AuthProviderEnumSchema } from "../types";
import { dummyProvider } from "./dummy";
import { oktaProvider } from "./okta";
import { IAuthProvider } from "./types";

const EnvProvidersSchema = z
  .array(AuthProviderEnumSchema)
  .min(
    1,
    "You need specifiy minimum one provider in VITE_ALLOWED_AUTH_PROVIDERS",
  );
// eslint-disable-next-line no-redeclare
type EnvProvidersSchema = z.infer<typeof EnvProvidersSchema>;

const PROVIDERS: Record<AuthProviderEnumSchema, IAuthProvider> = {
  dummy: dummyProvider,
  okta: oktaProvider,
};
const providersFromEnv = EnvProvidersSchema.parse(
  import.meta.env.VITE_ALLOWED_AUTH_PROVIDERS.split(","),
);

export const allowedAuthProviders = Object.fromEntries(
  Object.entries(PROVIDERS)
    .filter(([k]) => providersFromEnv.includes(k as AuthProviderEnumSchema))
    .map(([k, v]) => [k, v.meta]),
);

function isAllowedProviderByRuntime(providerId: AuthProviderEnumSchema) {
  return Object.keys(allowedAuthProviders).includes(providerId);
}

export function getProviderOrThrow(providerId: AuthProviderEnumSchema) {
  const provider = PROVIDERS[providerId];
  if (!provider) {
    throw new Error(`Provider id=${providerId} is invalid!`);
  }
  if (!isAllowedProviderByRuntime(providerId)) {
    throw new Error(
      `Provider id=${providerId} is not allowed! Tip: change configuration.`,
    );
  }
  return provider;
}
