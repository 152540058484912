import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/ui/table";
import { ChevronDownIcon, ChevronUpIcon } from "lucide-react";
import { useMemo, useState } from "react";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/ui/hover-card";
import { LogItem } from "@/types/types";

function unixTimeToEuropeanDate(unixTime) {
  const milliseconds = unixTime * 1000;
  const dateObject = new Date(milliseconds);

  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1;
  const year = dateObject.getFullYear();
  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  const seconds = dateObject.getSeconds();

  let minutesStr:string = ""+minutes, secondsStr:string = ""+seconds;
  if (minutes < 10)
    minutesStr =  "0" + minutes;
  if (seconds < 10)
    secondsStr = "0" + seconds;


  const formattedDate = `${day}/${month}/${year}`;
  const formattedTime = `${hours}:${minutesStr}:${secondsStr}`;

  return `${formattedDate} ${formattedTime}`;
}


interface LogsTableProps {
  data: LogItem[];
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
}

export const LogsTable = ({ data, isFetchingNextPage, hasNextPage } : LogsTableProps) => {
  const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'asc' });

  const sortedData = useMemo(() => {
    if (!data) return []; // Handle the case where data is undefined
    console.log(typeof data)
    console.log(data)
    let sortableData = [...data];
    if (sortConfig.key === 'date') {
      sortableData.sort((a, b) => {
        if (sortConfig.direction === 'asc') {
          return +a.sortKey - +b.sortKey;
        } else {
          return +b.sortKey - +a.sortKey;
        }
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const getIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? <ChevronUpIcon className="w-4 h-4 inline-block ml-1" /> : <ChevronDownIcon className="w-4 h-4 inline-block ml-1" />;
    }
    return null;
  };


  return (
    <>
      <Table className="min-w-full">
        <TableCaption></TableCaption>
        <TableHeader className="sticky top-0 bg-white z-10">
          <TableRow>
            <TableHead className="cursor-pointer w-[120px]" onClick={() => handleSort('date')}>Date {getIcon('date')}</TableHead>
            <TableHead className="cursor-pointer" onClick={() => handleSort('account')}>Account {getIcon('account')}</TableHead>
            <TableHead onClick={() => handleSort('action')}>Action {getIcon('action')}</TableHead>
            <TableHead className="max-w-[200px]" onClick={() => handleSort('resource')}>Resource {getIcon('resource')}</TableHead>
            <TableHead>Requester</TableHead>
            <TableHead>Approver</TableHead>
            <TableHead>Query</TableHead>
            <TableHead>Status</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody className="overflow-y-scroll" >
          {sortedData.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{unixTimeToEuropeanDate(item.sortKey)}</TableCell>
              <TableCell>{item.account}</TableCell>
              <TableCell>{item.action}</TableCell>
              <TableCell>{item.resource}</TableCell>
              <TableCell>{item.requester}</TableCell>
              <TableCell>{item.approver}</TableCell>
              <TableCell className="max-w-[500px] h-[36px]">
                <HoverCard>
                  <HoverCardTrigger className="flex align-middle mt-4">
                      <div className=" h-[36px] overflow-hidden text-ellipsis whitespace-nowrap">
                        {item.query}
                      </div>
                  </HoverCardTrigger>
                  <HoverCardContent className="min-w-[500px] break-words whitespace-pre-wrap">
                    {item.query}
                  </HoverCardContent>
                </HoverCard>
              </TableCell>
              <TableCell>{item.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {isFetchingNextPage && 
        <div className="flex justify-center">
          <p className="mb-3">Loading more...</p>
        </div>
      }
      {!hasNextPage &&
        <div className="flex justify-center">
          <p className="mb-3">No more logs to display.</p>
        </div>
      }
    </>
  );
};
