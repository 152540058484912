import { OktaAuth } from "@okta/okta-auth-js";
import * as z from "zod";

const OktaConfigSchema = z.object({
  issuer: z.string().min(1),
  clientId: z.string().min(1),
});

const config = OktaConfigSchema.parse({
  issuer: import.meta.env.VITE_ISSUER,
  clientId: import.meta.env.VITE_CLIENT_ID,
});

export const oktaAuth = new OktaAuth({
  ...config,
  redirectUri: window.location.origin + "/login/callback/okta",
  scopes: ["openid", "profile", "email", "groups"],
});
