import { getToken } from "@/auth";
import { redirector } from "@/auth/redirector";

import { Api } from "./__generated__/api";

export * from "./hooks";
export * from "./types";
export * from "./guards";

const BASE_URL = import.meta.env.VITE_BASE_URL;

export function clientApiFactory() {
  const client = new Api({
    baseURL: BASE_URL + "/api",
  });

  client.instance.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${getToken()}`;
    return config;
  });
  client.instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        redirector.store(window.location.pathname + window.location.search);
        window.location.replace("/");
      }
      throw error;
    },
  );
  return client;
}
