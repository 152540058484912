import { format } from "date-fns"
import { Calendar as CalendarIcon } from "lucide-react"
import { Button } from "@/ui/button"
import { Calendar } from "@/ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/ui/popover"

interface DatePickerProps {
  defaultDate?: Date;
  onSelect: (selectedDate: Date) => void;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
}

export const DatePicker = ({ defaultDate, onSelect, isOpen, onOpenChange}: DatePickerProps) => {
  const handleDateSelect = (selectedDate: Date) => {
    onSelect(selectedDate);
    onOpenChange(false); // Close the popover when a date is selected
  };

  return (
    <Popover open={isOpen} onOpenChange={onOpenChange}>
      <PopoverTrigger className="h-8 max-w-[140px]" asChild>
        <Button
          variant={"outline"}
          className="w-[280px] justify-start text-left font-normal"
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {defaultDate ? format(defaultDate, "dd/MM/yyyy") : "Select date"}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={defaultDate}
          onSelect={handleDateSelect}
          initialFocus={true}
          defaultMonth={defaultDate ?? new Date()}
          weekStartsOn={1} // Set the week to start on Monday (0 = Sunday, 1 = Monday)
          toDate={new Date()}
        />
      </PopoverContent>
    </Popover>
  );
};

export default DatePicker;
