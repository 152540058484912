import { RandomLoader } from "@nexus/lib-react/dist/core/Loading/RandomLoader";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useAuth } from "./AuthProvider";
import { getProviderOrThrow } from "./providers";
import { AuthProviderEnumSchema } from "./types";

export function LoginDispatcher(): React.ReactNode {
  const { isLoading, changeProvider } = useAuth();
  const { provider: rawProvider } = useParams();
  const provider = AuthProviderEnumSchema.parse(rawProvider);

  const providerObj = getProviderOrThrow(provider);

  useEffect(() => {
    changeProvider(provider, providerObj.workerComponent ?? (() => <></>));
  }, [providerObj.workerComponent, changeProvider, provider]);

  if (isLoading) {
    return <RandomLoader type="x" />;
  }
  return <providerObj.loginComponent />;
}
